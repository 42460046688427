.App {
  height: 100vh;
  text-align: center;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: 300;
  letter-spacing: 0.5px;
  margin-top: 80px;
  margin-bottom: 40px;
}

.view-toggler-container {
  margin: 10px;
  margin-bottom: 20px;
}
.view-option {
  color: rgba(0, 0, 0, 0.4);
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.76em;
  margin: 0 15px;
}
.active {
  padding-bottom: 4px;
  color: black;
  color: #53b1fd;
}
