.add-book-container {
  background: white;
  border-radius: 10px;
  padding: 40px;

  margin-bottom: 40px;
  max-width: 450px;
}

.add-book-intro {
  font-size: 0.9em;
  margin-bottom: 35px;
}

.book-form-title {
  padding-bottom: 10px;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 300;
}

#add-book-form {
  display: flex;
  flex-direction: column;
}

.add-book-field {
  padding: 8px;
  width: 300px;
  margin: 5px auto;
  font-size: 0.85em;
}

.add-book-submit-button {
  padding: 0;
  margin: 0;
  width: 320px;
  margin: 5px auto 0;
  padding: 8px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  /* background-color: rgba(3, 105, 246, .5); */
  background-color: #86c8fa;
  color: white;
  font-size: 0.8em;
  &:hover {
    background-color: #53b1fd;
  }
  &:active {
    outline: none;
    border: none;
  }
  &:focus {
    outline: none;
    border: none;
  }
}

.book-right {
  display: flex;
  flex-direction: row;
}
