.book-list {
  background-color: white;
  padding: 15px 40px;
  border-radius: 10px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3);
  font-weight: 300;
  width: 400px;
}

.book {
  text-align: left;
  margin: 22px 0;
  display: flex;
  justify-content: space-between;
}

.book-title {
  font-weight: 400;
}

.book-author {
  padding: 2px 0 8px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.9em;
}

.book-genre {
  background: #b9dcff;
  display: inline-block;
  padding: 4px 5px;
  border: 1px solid rgba(0, 0, 0, 0.01);
  border-radius: 3px;
  font-size: 0.6em;
  text-transform: uppercase;
  height: 13px;
}
